/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.tp-steps {
  margin: 2rem 0;
}

.tp-steps ol {
  margin: 0;
}

.tp-steps__item {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.tp-steps__item:not(:first-child) {
  margin-top: 0.7rem;
}

.tp-steps__item--number {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: start;
      align-self: flex-start;
  background-color: var(--primary-color);
  border-radius: 50%;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: bold;
  height: 2.5rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 1rem;
  min-width: 2.5rem;
  width: 2.5rem;
}

.tp-steps__item--field {
  padding-top: 0.5rem;
}