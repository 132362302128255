/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.tp-avantages {
  background: #f2f3f3;
  margin: 2rem 0;
  padding: 1rem 2rem;
}

.tp-avantagesGrid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 2fr auto;
}

@media print, screen and (max-width: 39.99875em) {
  .tp-avantagesGrid {
    grid-template-columns: 1fr;
  }
}
.tp-avantages__rte ul {
  list-style-type: initial;
  margin-bottom: 1rem;
}

.tp-avantagesEncart {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 0.3rem;
  font-size: 0.85rem;
  margin: 1rem;
  max-width: 300px;
  padding: 1rem 1rem 1.5rem;
}

@media print, screen and (max-width: 39.99875em) {
  .tp-avantagesEncart {
    margin: 0 auto 1.5rem;
  }
}
.tp-avantagesEncart__icon {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.tp-avantagesEncart__icon i {
  font-size: 2.2em;
}

.tp-avantagesEncart__title h3 {
  color: var(--primary-color);
}