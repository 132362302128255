/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.tp-taeg-table {
  margin: 2rem 0;
}

.tp-taeg-table table {
  border-spacing: 0;
  font-weight: bold;
  line-height: 1;
  margin: 1rem 0;
  text-align: center;
  width: 100%;
}

.tp-taeg-table table .text-light {
  font-weight: normal;
}

.tp-taeg-table table tr {
  width: 100%;
}

.tp-taeg-table table tr:last-child td:first-child {
  border-radius: 0 0 0 5px;
}

.tp-taeg-table table tr:last-child td:last-child {
  border-radius: 0 0 5px;
}

.tp-taeg-table table tr:first-child td:not(.empty-cell) {
  border-left: 1px solid #d9d9d9;
  border-radius: 5px 5px 0 0;
  border-top: 1px solid #d9d9d9;
}

.tp-taeg-table table tr td:not(.empty-cell) {
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 0.5rem 1rem;
}

.tp-taeg-table table tr td:not(.empty-cell):first-child {
  border-right: 1px solid #d9d9d9;
  width: 28%;
}

.tp-taeg-table table tr td:not(.empty-cell):first-child:not(.empty-cell) {
  border-left: 1px solid #d9d9d9;
}

.tp-taeg-table table tr td:not(.empty-cell):not(:first-child) {
  width: 36%;
}

.tp-taeg-table table tr td:not(.empty-cell):last-child {
  border-right: 1px solid #d9d9d9;
}

.tp-taeg-table table tr th {
  background-color: #3a913f;
  color: #fff;
  padding: 0.5rem 1rem;
}

.tp-taeg-table table tr th:first-child {
  border-radius: 5px 0 0;
  border-right: 1px solid #fff;
  width: 28%;
}

.tp-taeg-table table tr th:first-child:not(.empty-cell) {
  border-left: 1px solid #d9d9d9;
}

.tp-taeg-table table tr th:not(:first-child) {
  width: 36%;
}

.tp-taeg-table table tr th:last-child {
  border-right: 1px solid #d9d9d9;
}