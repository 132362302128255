/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.thumbnails-crosssell .thumbnails-crosssell-item .thumbnails-crosssell-subtitle__rte--icon {
  font-family: pf-icon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

.thumbnails-crosssell .thumbnails-crosssell-item .thumbnails-crosssell-subtitle__rte--icon::before {
  content: "\e937";
}

.thumbnails-crosssell {
  margin: 1rem 0;
}

.thumbnails-crosssell .grid-x {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.thumbnails-crosssell .thumbnails-crosssell-item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: calc(50% - 1rem);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background-color: #e6e6e6;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 1.5rem;
}

@media print, screen and (max-width: 39.99875em) {
  .thumbnails-crosssell .thumbnails-crosssell-item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: calc(100% - 1rem);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
.thumbnails-crosssell .thumbnails-crosssell-item:hover .thumbnails-crosssell-subtitle__rte {
  text-decoration: underline;
}

.thumbnails-crosssell .thumbnails-crosssell-item .thumbnails-crosssell-entry {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 1rem;
  padding-top: 0.55rem;
}

.thumbnails-crosssell .thumbnails-crosssell-item .thumbnails-crosssell-picto {
  background-color: var(--primary-color);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 3rem;
  min-height: 3rem;
  min-width: 3rem;
  width: 3rem;
}

.thumbnails-crosssell .thumbnails-crosssell-item .thumbnails-crosssell-picto span.icon {
  color: #fff;
  display: block;
  font-size: 2rem;
  margin: auto;
}

.thumbnails-crosssell .thumbnails-crosssell-item .thumbnails-crosssell-title__rte {
  font-size: 1.125rem;
}

.thumbnails-crosssell .thumbnails-crosssell-item .thumbnails-crosssell-subtitle__rte {
  font-weight: bold;
  margin-bottom: 0.5rem;
  position: relative;
  width: calc(100% - 2rem);
}

.thumbnails-crosssell .thumbnails-crosssell-item .thumbnails-crosssell-subtitle__rte--icon {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 0.15rem;
  position: absolute;
  right: -1.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.thumbnails-crosssell .thumbnails-crosssell-item .thumbnails-crosssell-text__rte {
  color: #292c2e;
}