/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.tp-bloc-recap-credit {
  border-radius: 25px 25px 15px 15px;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  margin: 2rem 0;
  overflow: hidden;
  padding: 1.25rem 1rem 1rem;
  position: relative;
}

.tp-bloc-recap-credit::before {
  background-color: var(--primary-color);
  content: "";
  display: block;
  height: 0.25rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.tp-bloc-recap-credit__title {
  color: var(--primary-color);
  text-align: center;
}

.tp-bloc-recap-credit__amounts {
  margin-bottom: 1rem;
}

.tp-bloc-recap-credit__amounts--item {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 33%;
}

.tp-bloc-recap-credit__amounts--item i {
  border: 2px solid #292c2e;
  border-radius: 100%;
  display: inline-block;
  font-size: 2rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

.tp-bloc-recap-credit__amounts--item .amount,
.tp-bloc-recap-credit__amounts--item .label {
  color: var(--primary-color);
  font-weight: bold;
}

.tp-bloc-recap-credit__amounts--item .amount {
  font-size: 1.5rem;
}

.tp-bloc-recap-credit__amounts--item .label {
  font-size: 1.125rem;
}

.tp-bloc-recap-credit__terms {
  font-size: 0.7rem;
}

.tp-bloc-recap-credit__terms p {
  margin-bottom: 0;
}