/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.tp-pricing-bloc {
  margin: 2rem 0;
}

.tp-pricing-bloc h2 {
  font-size: 1rem;
}

.tp-pricing-bloc__grid {
  padding: 0 2rem;
}

.tp-pricing-bloc__grid h3 {
  font-weight: normal;
}

.tp-pricing-bloc__columns {
  -webkit-column-gap: 20%;
     -moz-column-gap: 20%;
          column-gap: 20%;
  display: grid;
  grid-template-columns: repeat(2, 40%);
  margin: 0 0 1.5rem;
  row-gap: 1rem;
}

@media print, screen and (max-width: 39.99875em) {
  .tp-pricing-bloc__columns {
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
  }
}
.tp-pricing-bloc__columns > li > div {
  border: 2px solid var(--primary-color);
  padding: 1rem;
  text-align: center;
}

.tp-pricing-bloc__columns > li span.title {
  color: var(--primary-color);
  font-size: 0.85rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.tp-pricing-bloc__columns > li p {
  font-size: 0.9rem;
  margin-bottom: 0;
}