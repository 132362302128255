/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.tp-information-banner {
  background-color: var(--background-color);
  margin: 2rem 0;
  padding: 1rem;
}

.tp-information-banner__img-container {
  -ms-flex-item-align: stretch;
      align-self: stretch;
  position: relative;
}

.tp-information-banner__img-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-height: calc(100% + 2rem + 1.4rem);
}

.tp-information-banner__text {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

@media print, screen and (min-width: 40em) {
  .tp-information-banner__text {
    margin: 0;
  }
}
.tp-information-banner__button a {
  font-size: 0.7rem;
  font-weight: bold;
  line-height: 1.1;
  margin: 0;
}