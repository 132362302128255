/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.full-background.green {
  background-color: #EFF4F2;
  padding: 2.5rem 0;
}

@media print, screen and (max-width: 39.99875em) {
  .full-background.green {
    padding: 2rem 0;
  }
}
.tp-bloc-details-credit {
  background: #fff;
  padding: 1.5rem;
  position: relative;
}

@media print, screen and (max-width: 39.99875em) {
  .tp-bloc-details-credit {
    padding: 1rem;
  }
}
.tp-bloc-details-credit p {
  width: 100%;
}

.tp-bloc-details-credit h3 {
  font-size: 2rem;
  line-height: 2.5rem;
  text-align: center;
  margin-bottom: 2.5rem;
}

@media print, screen and (max-width: 39.99875em) {
  .tp-bloc-details-credit h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }
}
.tp-bloc-details-credit__calculation ol {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  margin: 0;
  text-align: initial;
}

.tp-bloc-details-credit__calculation ol li {
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #DCDEE0;
  padding: 0.5rem 0;
  color: #4B4F54;
}

.tp-bloc-details-credit__calculation ol li:nth-child(odd) {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  font-size: 1rem;
}

.tp-bloc-details-credit__calculation ol li:first-child, .tp-bloc-details-credit__calculation ol li:nth-first-child(2) {
  border-bottom: 0;
  padding-bottom: 0;
}

.tp-bloc-details-credit__calculation ol li:last-child, .tp-bloc-details-credit__calculation ol li:nth-last-child(2) {
  border-bottom: 0;
  padding-bottom: 0;
}

.tp-bloc-details-credit__calculation ol li:nth-child(even) {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: bold;
  text-align: right;
}

@media print, screen and (max-width: 39.99875em) {
  .tp-bloc-details-credit__calculation ol li:nth-child(even) {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
}
.tp-bloc-details-credit__calculation > .cell {
  margin-bottom: 2rem;
  min-width: 13rem;
}

@media print, screen and (min-width: 30em) {
  .tp-bloc-details-credit__calculation > .cell {
    margin-bottom: 1.5rem;
  }
}
.tp-bloc-details-credit__calculation .bloc-green {
  color: #4B4F54;
  background-color: #E8F5F3;
  border-radius: 6px;
  width: 100%;
  padding: 0.25rem 1rem;
  margin-bottom: 1.5rem;
}

@media print, screen and (max-width: 39.99875em) {
  .tp-bloc-details-credit__calculation .bloc-green {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 1rem;
  }
}
.tp-bloc-details-credit__savoir-plus {
  margin-bottom: 2rem;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.tp-bloc-details-credit__savoir-plus .grid-container {
  width: 100%;
  padding: 0;
}

.tp-bloc-details-credit__savoir-plus i {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-right: 0.625rem;
}

.tp-bloc-details-credit__savoir-plus a {
  text-decoration: underline;
}

.tp-bloc-details-credit__terms p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #4B4F54;
  margin-bottom: 0;
}