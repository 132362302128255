/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.tp-table-garantie-mecanique {
  margin: 2rem 0;
}

.tp-table-garantie-mecanique__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.tp-table-garantie-mecanique__container table {
  border-left-width: 0;
  border-spacing: 0;
  font-size: 0.875rem;
  width: 100%;
}

.tp-table-garantie-mecanique__container table tbody,
.tp-table-garantie-mecanique__container table tr,
.tp-table-garantie-mecanique__container table th,
.tp-table-garantie-mecanique__container table td {
  display: block;
  height: 100%;
  width: 100%;
}

.tp-table-garantie-mecanique__container table th,
.tp-table-garantie-mecanique__container table td {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 5px;
  text-align: center;
}

.tp-table-garantie-mecanique__container table tr {
  border-right: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  height: 53px;
}

.tp-table-garantie-mecanique__container table tr:last-child {
  border-bottom: 1px solid #d9d9d9;
}

.tp-table-garantie-mecanique__container__left {
  font-weight: bold;
  width: 10rem;
}

@media screen and (min-width: 64em) {
  .tp-table-garantie-mecanique__container__left {
    width: 15rem;
  }
}
.tp-table-garantie-mecanique__container__left table tr {
  border-left: 1px solid #d9d9d9;
}

.tp-table-garantie-mecanique__container__left table tr.emptycell {
  border-left-width: 0;
  border-top-width: 0;
}

.tp-table-garantie-mecanique__container__right {
  width: calc(100% - 10rem);
}

@media screen and (min-width: 64em) {
  .tp-table-garantie-mecanique__container__right {
    width: calc(100% - 15rem);
  }
}
.tp-table-garantie-mecanique__container__slider-container th {
  background-color: var(--primary-color);
  color: #fff;
}

.tp-table-garantie-mecanique__container .slick-slide:last-child table {
  width: calc(100% - 3px);
}

.tp-table-garantie-mecanique__container .slick-dots li button .slick-dot-icon {
  border: 2px solid var(--primary-color);
  border-radius: 100%;
  display: block;
  height: 1.3rem;
  position: relative;
  width: 1.3rem;
}

.tp-table-garantie-mecanique__container .slick-dots li button .slick-dot-icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 100%;
  content: "";
  display: block;
  height: 0.8rem;
  margin: 0;
  width: 0.8rem;
}

.tp-table-garantie-mecanique__container .slick-dots li.slick-active button .slick-dot-icon::before {
  background-color: var(--primary-color);
}

.tp-table-garantie-mecanique__container .slick-arrow {
  color: var(--primary-color);
  height: 2rem;
  left: calc(50% - 6rem);
  top: calc(100% + 0.8rem);
  width: 2rem;
  z-index: 1;
}

.tp-table-garantie-mecanique__container .slick-arrow span[class*=i-] {
  font-size: 2rem;
}

.tp-table-garantie-mecanique__container .slick-arrow.slick-next {
  left: unset;
  right: calc(50% - 6rem);
}

.tp-table-garantie-mecanique__container .slick-arrow.slick-disabled {
  opacity: 0.5;
}