/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.tp-table-options-capital {
  margin: 2rem 0;
}

.tp-table-options-capital__tip {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 2px solid #d9d9d9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 1rem 2rem;
  max-width: 25rem;
  min-height: 4rem;
  padding: 1rem 1rem 1rem 2rem;
  position: relative;
}

.tp-table-options-capital__tip::before {
  background-color: #fff;
  border: 2px solid #d9d9d9;
  border-bottom-left-radius: 2rem;
  border-right: 0;
  border-top-left-radius: 2rem;
  content: "";
  height: 4rem;
  left: -2rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 2rem;
}

.tp-table-options-capital__tip i {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--primary-color);
  border-radius: 50%;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 2rem;
  height: 2.5rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: -1.25rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 2.5rem;
}

.tp-table-options-capital__tip p {
  margin-bottom: 0;
}

.tp-table-options-capital__table {
  font-size: 0.875rem;
}

.tp-table-options-capital__table__title {
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  padding: 5px;
  text-align: center;
}

.tp-table-options-capital__table__title,
.tp-table-options-capital__table th {
  background-color: var(--primary-color);
  color: #fff;
  font-weight: bold;
}

.tp-table-options-capital__table-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.tp-table-options-capital__table-container table {
  border-left-width: 0;
  border-spacing: 0;
  width: 100%;
}

.tp-table-options-capital__table-container table tbody,
.tp-table-options-capital__table-container table tr,
.tp-table-options-capital__table-container table th,
.tp-table-options-capital__table-container table td {
  display: block;
  height: 100%;
  width: 100%;
}

.tp-table-options-capital__table-container table th,
.tp-table-options-capital__table-container table td {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 5px;
  text-align: center;
}

.tp-table-options-capital__table-container table tr {
  border-right: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
}

.tp-table-options-capital__table-container table tr:last-child {
  border-bottom: 1px solid #d9d9d9;
}

.tp-table-options-capital__table-container__left {
  font-weight: bold;
  width: 7rem;
}

@media screen and (min-width: 64em) {
  .tp-table-options-capital__table-container__left {
    width: 15rem;
  }
}
.tp-table-options-capital__table-container__left table tr {
  border-left: 1px solid #d9d9d9;
}

.tp-table-options-capital__table-container__right {
  width: calc(100% - 7rem);
}

@media screen and (min-width: 64em) {
  .tp-table-options-capital__table-container__right {
    width: calc(100% - 15rem);
  }
}
.tp-table-options-capital__table-container__right th {
  font-weight: normal;
}

.tp-table-options-capital__table-container .slick-list {
  border-right: 1px solid #d9d9d9;
}

.tp-table-options-capital__table-container .slick-list .slick-slide > div {
  height: calc(12rem + 7px);
}

.tp-table-options-capital__table-container .slick-dots li button .slick-dot-icon {
  border: 2px solid var(--primary-color);
  border-radius: 100%;
  display: block;
  height: 1.3rem;
  position: relative;
  width: 1.3rem;
}

.tp-table-options-capital__table-container .slick-dots li button .slick-dot-icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 100%;
  content: "";
  display: block;
  height: 0.8rem;
  margin: 0;
  width: 0.8rem;
}

.tp-table-options-capital__table-container .slick-dots li.slick-active button .slick-dot-icon::before {
  background-color: var(--primary-color);
}

.tp-table-options-capital__table-container .slick-dots li .slick-arrow {
  color: var(--primary-color);
  height: 2rem;
  left: calc(50% - 6rem);
  top: calc(100% + 0.8rem);
  width: 2rem;
  z-index: 1;
}

.tp-table-options-capital__table-container .slick-dots li .slick-arrow span[class*=i-] {
  font-size: 2rem;
}

.tp-table-options-capital__table-container .slick-dots li .slick-arrow.slick-next {
  left: unset;
  right: calc(50% - 6rem);
}

.tp-table-options-capital__table-container .slick-dots li .slick-arrow.slick-disabled {
  opacity: 0.5;
}